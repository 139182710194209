<template>
  <fragment>
    <wx-form-container class="wizard">
      <v-form ref="productionUnitForm" lazy-validation>
        <fieldset>
          <legend class="legend-header top-legend">{{ $t("common.wizardItemDetails") }}</legend>
          <!-- - - - - - - - - - - - -     Name     - - - - - - - - - - - - - - - -->
          <wx-text-field
            v-model.trim="name"
            class="huge-field-width single-field-row"
            :rules="[() => validateName()]"
            :label="$t('common.name') + ' *'"
            :counter="productionUnitNameMaxLength"
            :maxlength="productionUnitNameMaxLength"
          />
        </fieldset>

        <!-- - - - - - - -    Production Unit Quality  - - - - - - - - -  -->
        <fieldset v-if="!isTargetQualityAuthorized()">
          <legend>
            {{ $t("productionUnit.details.qualitySubSectionTitle") }}
            <wx-contextualized-help :tooltip-text="$t('productionUnit.details.qualitySubSectionTooltip')" />
          </legend>
          <wx-text-field
            v-model.number="globalQuality"
            type="number"
            min="0"
            max="100"
            class="narrow-field-width"
            :label="$t('productionUnit.details.qualityValue') + ' *'"
            :suffix="$t('productionUnit.details.qualityValuePercent')"
            :rules="[() => validateQuality()]"
          />
        </fieldset>

        <!-- - - - - - - -    Production Unit Objectives    - - - - - - - - -  -->
        <fieldset class="inline-fields">
          <legend>{{ $t("productionUnit.details.targetsSubSectionTitle") }}</legend>
          <div class="d-flex flex-small-column-gap">
            <wx-text-field
              v-model.number="availabilityTarget"
              type="number"
              min="1"
              max="100"
              class="narrow-field-width"
              suffix="%"
              :label="$t('productionUnit.details.targetAvailabilityValue') + ' *'"
              :disabled="!isTargetAvailabilityAuthorized()"
              :rules="[() => validateAvailabilityTarget()]"
            />
            <wx-text-field
              v-model.number="performanceTarget"
              type="number"
              min="1"
              max="100"
              class="narrow-field-width"
              suffix="%"
              :title="getPerformanceTargetTooltip()"
              :label="getPerformanceTargetMandatoryMark()"
              :disabled="!isTargetPerformanceAuthorized()"
              :rules="[() => validatePerformanceTarget()]"
            />
            <wx-text-field
              v-if="isTargetQualityAuthorized()"
              v-model.number="qualityTarget"
              type="number"
              min="1"
              max="100"
              class="narrow-field-width"
              suffix="%"
              :title="getQualityTargetTooltip()"
              :label="getQualityTargetMandatoryMark()"
              :disabled="!isTargetQualityAuthorized()"
              :rules="[() => validateQualityTarget()]"
            />
          </div>
        </fieldset>
        <fieldset class="inline-fields">
          <div class="d-flex flex-small-column-gap">
            <wx-text-field
              v-model.number="oeeValue"
              type="number"
              min="1"
              max="100"
              class="narrow-field-width"
              suffix="%"
              :label="$t('productionUnit.details.targetOEEValue')"
              disabled
            />
            <wx-text-field
              v-model.number="ooeTarget"
              type="number"
              min="1"
              max="100"
              class="narrow-field-width"
              suffix="%"
              :title="getOOETargetTooltip()"
              :label="getOOETargetMandatoryMark()"
              :disabled="!isTargetOOEAuthorized()"
              :rules="[() => validateOOETarget()]"
            />
          </div>
        </fieldset>

        <!-- - - - - - - - - - - - -     Converted Unit Name     - - - - - - - - - - - - - - - -->
        <fieldset>
          <legend>
            {{ $t("productionUnit.details.unitSubSectionTitle") }}
            <wx-contextualized-help :tooltip-text="$t('productionUnit.details.unitSubSectionTooltip')" />
          </legend>
          <wx-autocomplete
            v-model="convertedUnitId"
            item-text="name"
            item-value="id"
            validate-on-blur
            append-icon=""
            class="narrow-field-width"
            :items="availableUnitNames"
            :label="$t('productionUnit.details.convertedUnitName')"
            :rules="[() => validateConvertedUnitId()]"
            disabled
          >
          </wx-autocomplete>
        </fieldset>

        <!-- - - - - - - - - - - - -     Cost per Hour  - - - - - - - - - - - - - - - -->
        <fieldset class="inline-fields" v-if="isLoggedInUserFinancialAdmin">
          <legend>
            {{ $t("productionUnit.details.costPerHour") }}
            <wx-contextualized-help :tooltip-text="$t('productionUnit.details.costPerHourTooltip')" />
          </legend>
          <wx-text-field
            v-model.number="costPerHour"
            type="number"
            min="0"
            class="narrow-field-width"
            :label="currencyCode"
            :rules="[() => validateCostPerHour()]"
          />
        </fieldset>

        <v-expansion-panels>
          <v-expansion-panel class="v-expansion-panel--fieldset-toggle">
            <v-expansion-panel-header>
              {{ $t("productionUnit.details.delays.title") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- - - - - - - - - - - - -     Downtime Timeout  - - - - - - - - - - - - - - - -->
              <fieldset class="inline-fields">
                <legend>
                  {{ $t("productionUnit.details.downtimeDelay") }}
                  <wx-contextualized-help :tooltip-text="$t('productionUnit.details.downtimeDelayTooltip')" />
                </legend>
                <div class="d-flex flex-small-column-gap">
                  <wx-text-field
                    v-model.number="downtimeTimeoutHours"
                    type="number"
                    min="0"
                    max="23"
                    class="narrow-field-width"
                    :label="$t('productionUnit.details.hours') + ' *'"
                    :rules="[() => this.validateHour(this.downtimeTimeoutHours)]"
                  />
                  <wx-text-field
                    v-model.number="downtimeTimeoutMinutes"
                    type="number"
                    min="0"
                    max="59"
                    class="narrow-field-width"
                    :label="$t('productionUnit.details.minutes') + ' *'"
                    :rules="[() => this.validateMinute(this.downtimeTimeoutMinutes)]"
                  />
                  <wx-text-field
                    v-model.number="downtimeTimeoutSeconds"
                    type="number"
                    min="0"
                    max="59"
                    class="narrow-field-width"
                    :label="$t('productionUnit.details.seconds') + ' *'"
                    :rules="[() => this.validateSecond(this.downtimeTimeoutSeconds)]"
                  />
                </div>
              </fieldset>

              <!-- - - - - - - - - - - - -     Downtime Justification Delay  - - - - - - - - - - - - - - - -->
              <fieldset class="inline-fields">
                <legend>
                  {{ $t("productionUnit.details.downtimeJustificationDelay") }}
                  <wx-contextualized-help
                    :tooltip-text="$t('productionUnit.details.downtimeJustificationDelayTooltip')"
                  />
                </legend>
                <div class="d-flex flex-small-column-gap">
                  <wx-text-field
                    v-model.number="downtimeJustificationDelayHours"
                    type="number"
                    min="0"
                    max="23"
                    class="narrow-field-width"
                    :label="$t('productionUnit.details.hours') + ' *'"
                    :rules="[() => this.validateHour(this.downtimeJustificationDelayHours)]"
                  />
                  <wx-text-field
                    v-model.number="downtimeJustificationDelayMinutes"
                    type="number"
                    min="0"
                    max="59"
                    class="narrow-field-width"
                    :label="$t('productionUnit.details.minutes') + ' *'"
                    :rules="[() => this.validateMinute(this.downtimeJustificationDelayMinutes)]"
                  />
                  <wx-text-field
                    v-model.number="downtimeJustificationDelaySeconds"
                    type="number"
                    min="0"
                    max="59"
                    class="narrow-field-width"
                    :label="$t('productionUnit.details.seconds') + ' *'"
                    :rules="[() => this.validateSecond(this.downtimeJustificationDelaySeconds)]"
                  />
                </div>
              </fieldset>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <!-- - - - - - - - - - - - -     Giveaway Sampling  - - - - - - - - - - - - - - - -->
        <v-expansion-panels v-if="isGiveawaySamplingEnabled()">
          <v-expansion-panel class="mt-4 v-expansion-panel--fieldset-toggle">
            <v-expansion-panel-header>
              {{ $t("productionUnit.details.giveawaySampling.title") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <fieldset class="mt-4 inline-fields">
                <wx-text-field
                  v-model.number="giveawaySampleCount"
                  type="number"
                  min="1"
                  max="50"
                  class="narrow-field-width"
                  :label="$t('productionUnit.details.giveawaySampling.requiredSampleCount') + ' *'"
                  :rules="[() => this.validateGiveawaySampleCount()]"
                />
              </fieldset>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <!-- - - - - - - - - - - - -     Advanced settings  - - - - - - - - - - - - - - - -->
        <v-expansion-panels v-if="isWorximityAdmin">
          <v-expansion-panel class="mt-4 v-expansion-panel--fieldset-toggle">
            <v-expansion-panel-header>
              {{ $t("productionUnit.details.advancedSettings.title") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>

              <!-- - - - - - - - - - - - -     End of Downtime Timeout - - - - - - - - - - - - - - - -->
              <fieldset class="inline-fields">
                <legend>
                  {{ $t("productionUnit.details.endOfDowntimeTimeout") }}
                  <wx-contextualized-help :tooltip-text="$t('productionUnit.details.endOfDowntimeTimeoutTooltip')" />
                </legend>
                <div class="d-flex flex-small-column-gap">
                  <wx-text-field
                    v-model.number="endOfDowntimeTimeoutHours"
                    type="number"
                    min="0"
                    max="23"
                    class="narrow-field-width"
                    :label="$t('productionUnit.details.hours') + ' *'"
                    :rules="[() => this.validateHour(this.endOfDowntimeTimeoutHours)]"
                  />
                  <wx-text-field
                    v-model.number="endOfDowntimeTimeoutMinutes"
                    type="number"
                    min="0"
                    max="59"
                    class="narrow-field-width"
                    :label="$t('productionUnit.details.minutes') + ' *'"
                    :rules="[() => this.validateMinute(this.endOfDowntimeTimeoutMinutes)]"
                  />
                  <wx-text-field
                    v-model.number="endOfDowntimeTimeoutSeconds"
                    type="number"
                    min="0"
                    max="59"
                    class="narrow-field-width"
                    :label="$t('productionUnit.details.seconds') + ' *'"
                    :rules="[() => this.validateSecond(this.endOfDowntimeTimeoutSeconds)]"
                  />
                </div>
              </fieldset>

              <!-- - - - - - - - - - - - -     Measure Hole Timeout - - - - - - - - - - - - - - - -->
              <fieldset class="mt-4 inline-fields">
                <legend>
                  {{ $t("productionUnit.details.advancedSettings.measureHoleTimeout") }}
                  <wx-contextualized-help
                    :tooltip-text="$t('productionUnit.details.advancedSettings.measureHoleTimeoutTooltip')"
                  />
                </legend>
                <div class="d-flex flex-small-column-gap">
                  <wx-text-field
                    v-model.number="measureHoleTimeoutHours"
                    type="number"
                    min="0"
                    max="23"
                    class="narrow-field-width"
                    :label="$t('productionUnit.details.hours') + ' *'"
                    :rules="[() => this.validateHour(this.measureHoleTimeoutHours)]"
                  />
                  <wx-text-field
                    v-model.number="measureHoleTimeoutMinutes"
                    type="number"
                    min="0"
                    max="59"
                    class="narrow-field-width"
                    :label="$t('productionUnit.details.minutes') + ' *'"
                    :rules="[() => this.validateMinute(this.measureHoleTimeoutMinutes)]"
                  />
                  <wx-text-field
                    v-model.number="measureHoleTimeoutSeconds"
                    type="number"
                    min="0"
                    max="59"
                    class="narrow-field-width"
                    :label="$t('productionUnit.details.seconds') + ' *'"
                    :rules="[() => this.validateSecond(this.measureHoleTimeoutSeconds)]"
                  />
                </div>
              </fieldset>

              <!-- - - - - - - - -   Timeout relative to scheduled (planned) downtime  - - - - - - - - - - -->
              <fieldset class="mt-4 inline-fields">
                <legend>
                  {{ $t("productionUnit.details.advancedSettings.recurringDowntimeTrigger") }}
                  <wx-contextualized-help
                    :tooltip-text="$t('productionUnit.details.advancedSettings.recurringDowntimeTriggerTooltip')"
                  />
                </legend>
                <div class="d-flex flex-small-column-gap">
                  <wx-text-field
                    v-model.number="minScheduledDowntimeDurationHours"
                    type="number"
                    min="0"
                    max="23"
                    class="narrow-field-width"
                    :label="$t('productionUnit.details.hours') + ' *'"
                    :rules="[() => this.validateHour(this.minScheduledDowntimeDurationHours)]"
                  />
                  <wx-text-field
                    v-model.number="minScheduledDowntimeDurationMinutes"
                    type="number"
                    min="0"
                    max="59"
                    class="narrow-field-width"
                    :label="$t('productionUnit.details.minutes') + ' *'"
                    :rules="[() => this.validateMinute(this.minScheduledDowntimeDurationMinutes)]"
                  />
                  <wx-text-field
                    v-model.number="minScheduledDowntimeDurationSeconds"
                    type="number"
                    min="0"
                    max="59"
                    class="narrow-field-width"
                    :label="$t('productionUnit.details.seconds') + ' *'"
                    :rules="[() => this.validateSecond(this.minScheduledDowntimeDurationSeconds)]"
                  />
                </div>
              </fieldset>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <form-footer-actions :click-submit="submit" cancel-to="toProductionUnits" />
      </v-form>
    </wx-form-container>
  </fragment>
</template>

<script>
import WxFormContainer from "@/components/ui/WxFormContainer";
import FormFooterActions from "@/components/ui/FormFooterActions";
import WxTextField from "@/components/ui/WxTextField";
import WxAutocomplete from "@/components/ui/WxAutocomplete";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import ErrorHandling from "@/components/ErrorHandling";
import RouteService from "@/router/RouteService";
import Helpers from "@/helpers";
import Validations from "@/components/validations";
import { mapActions, mapGetters } from "vuex";
import PackageFeatures from "@/components/PackageFeatures";
import WxContextualizedHelp from "@/components/ui/WxContextualizedHelp";
import DemoService from "@/components/DemoService";

function isInteger(str) {
  const n = parseInt(str);
  return n > 0 && n === +str;
}

export default {
  name: "ProductionUnitWizard",
  components: {
    WxFormContainer,
    FormFooterActions,
    WxTextField,
    WxAutocomplete,
    WxContextualizedHelp,
  },
  props: {
    value: Boolean,
    initialProductionUnit: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      name: null,

      downtimeTimeoutHours: null,
      downtimeTimeoutMinutes: null,
      downtimeTimeoutSeconds: null,

      endOfDowntimeTimeoutHours: null,
      endOfDowntimeTimeoutMinutes: null,
      endOfDowntimeTimeoutSeconds: null,

      downtimeJustificationDelayHours: null,
      downtimeJustificationDelayMinutes: null,
      downtimeJustificationDelaySeconds: null,

      availabilityTarget: null,
      performanceTarget: null,
      qualityTarget: null,
      ooeTarget: null,

      globalQuality: null,

      convertedUnitId: null,
      costPerHour: null,
      currencyCode: null,

      giveawaySampleCount: null,
      minSampleCount: 1,
      maxSampleCount: 50,
      giveawaySampleSessionDurationHours: null,
      giveawaySampleSessionDurationMinutes: null,

      measureHoleTimeoutHours: 0,
      measureHoleTimeoutMinutes: 1,
      measureHoleTimeoutSeconds: 0,

      minScheduledDowntimeDurationHours: 0,
      minScheduledDowntimeDurationMinutes: 1,
      minScheduledDowntimeDurationSeconds: 0,

      productionUnitNameMaxLength: Validations.productionUnitNameMaxLength,
    };
  },
  watch: {
    initialProductionUnit() {
      if (this.initialProductionUnit) {
        this.name = this.initialProductionUnit.name;
        this.name = DemoService.replaceProductionUnitNameIfNecessary(this.email , this.productionUnit.name, this.$route.params.name);
        this.availabilityTarget = this.getAvailabilityTarget(this.initialProductionUnit.objectives);
        this.performanceTarget = this.getPerformanceTarget(this.initialProductionUnit.objectives);
        if (!this.isTargetPerformanceAuthorized() && this.performanceTarget === null) {
          this.performanceTarget = 100;
        }
        this.qualityTarget = this.getQualityTarget(this.initialProductionUnit.objectives);
        if (!this.isTargetQualityAuthorized() && this.qualityTarget === null) {
          this.qualityTarget = this.initialProductionUnit.globalQuality;
        }
        this.globalQuality = this.initialProductionUnit.quality;
        this.ooeTarget = this.getOOETarget(this.initialProductionUnit.objectives);
        if (!this.isTargetOOEAuthorized() && this.ooeTarget === null) {
          this.ooeTarget = 100;
        }
        this.costPerHour = this.initialProductionUnit.cost_per_hour;
      }
    },
  },
  computed: {
    ...mapGetters("packages", ["puHasRequiredFeature"]),
    ...mapGetters("dashboard", ["activeProductionUnitId"]),
    ...mapGetters("user", ["isLoggedInUserFinancialAdmin", "isWorximityAdmin", "email"]),
    ...mapGetters("navigation", ["activeFactory"]),
    availableUnitNames() {
      return Validations.getProductionUnitAvailableUnits();
    },
    oeeValue() {
      const avail = this.availabilityTarget ? this.availabilityTarget / 100.0 : 1.0;
      const perf = this.performanceTarget ? this.performanceTarget / 100.0 : 1.0;
      let qual;
      if (this.isTargetQualityAuthorized()) {
        qual = this.qualityTarget ? this.qualityTarget / 100.0 : 1.0;
      } else {
        qual = this.globalQuality ? this.globalQuality / 100.0 : 1.0;
      }
      return Math.round(100.0 * avail * perf * qual);
    },
  },

  methods: {
    ...mapActions("operation", ["showOperationSuccess", "showOperationError"]),
    ...mapActions("dashboard", ["setJustificationDelayInSeconds"]),
    getRouteBack() {
      return RouteService.toProductionUnits();
    },
    fetchProductionUnit() {
      return ProductionUnitService.getProductionUnit(this.$route.params.id)
        .then((response) => this.handleProductionUnitRetrievalResponse(response))
        .catch((error) => this.handleProductionUnitRetrievalError(error.response));
    },
    setDowntimeTimeoutValues(timeoutInSeconds) {
      const duration = Helpers.toHoursMinutesSecondsDuration(timeoutInSeconds);
      this.downtimeTimeoutHours = duration.hours;
      this.downtimeTimeoutMinutes = duration.minutes;
      this.downtimeTimeoutSeconds = duration.seconds;
    },
    setEndOfDowntimeTimeoutValues(timeoutInSeconds) {
      const duration = Helpers.toHoursMinutesSecondsDuration(timeoutInSeconds);
      this.endOfDowntimeTimeoutHours = duration.hours;
      this.endOfDowntimeTimeoutMinutes = duration.minutes;
      this.endOfDowntimeTimeoutSeconds = duration.seconds;
    },
    setJustificationTimeoutValues(timeoutInSeconds) {
      const duration = Helpers.toHoursMinutesSecondsDuration(timeoutInSeconds);
      this.downtimeJustificationDelayHours = duration.hours;
      this.downtimeJustificationDelayMinutes = duration.minutes;
      this.downtimeJustificationDelaySeconds = duration.seconds;
    },
    setMeasureHoleTimeoutValues(timeoutInSeconds) {
      const duration = Helpers.toHoursMinutesSecondsDuration(timeoutInSeconds);
      this.measureHoleTimeoutHours = duration.hours;
      this.measureHoleTimeoutMinutes = duration.minutes;
      this.measureHoleTimeoutSeconds = duration.seconds;
    },
    setMinScheduledDowntimeDuration(timeoutInSeconds) {
      const duration = Helpers.toHoursMinutesSecondsDuration(timeoutInSeconds);
      this.minScheduledDowntimeDurationHours = duration.hours;
      this.minScheduledDowntimeDurationMinutes = duration.minutes;
      this.minScheduledDowntimeDurationSeconds = duration.seconds;
    },
    handleProductionUnitRetrievalResponse(httpResponse) {
      if (httpResponse.status === 200) {
        this.productionUnit = httpResponse.data;
        this.name = DemoService.replaceProductionUnitNameIfNecessary(this.email , this.productionUnit.name, this.$route.params.name);
        this.availabilityTarget = this.getAvailabilityTarget(this.productionUnit.objectives);
        this.performanceTarget = this.getPerformanceTarget(this.productionUnit.objectives);
        if (!this.isTargetPerformanceAuthorized() && this.performanceTarget === null) {
          this.performanceTarget = 100;
        }
        this.qualityTarget = this.getQualityTarget(this.productionUnit.objectives);
        if (!this.isTargetQualityAuthorized() && this.qualityTarget === null) {
          this.qualityTarget = this.productionUnit.quality;
        }
        this.globalQuality = this.productionUnit.quality;

        this.ooeTarget = this.getOOETarget(this.productionUnit.objectives);
        if (!this.isTargetOOEAuthorized() && this.ooeTarget === null) {
          this.ooeTarget = 100;
        }

        this.setDowntimeTimeoutValues(this.productionUnit.downtime_timeout_in_seconds);
        this.setEndOfDowntimeTimeoutValues(this.productionUnit.end_downtime_timeout_in_seconds);
        this.setJustificationTimeoutValues(this.productionUnit.downtime_justification_delay_in_seconds);
        this.setMeasureHoleTimeoutValues(this.productionUnit.measure_hole_timeout_in_seconds);
        this.setMinScheduledDowntimeDuration(this.productionUnit.recurring_downtime_trigger_in_seconds);

        const unitName = this.productionUnit.converted_unit_name;
        if (Validations.isProductionUnitConvertedUnitValid(unitName)) {
          this.convertedUnitId = unitName;
        } else {
          this.convertedUnitId = ProductionUnitService.getDefaultUnit();
        }

        this.costPerHour = this.productionUnit.cost_per_hour;
        this.currencyCode = this.activeFactory.currency;

        const giveawaySampling = this.productionUnit.giveaway_sampling_configuration;
        if (giveawaySampling) {
          this.giveawaySampleCount = giveawaySampling.sample_count;
          const duration = Helpers.toHoursMinutesDuration(
            giveawaySampling.sampling_session_duration_minutes * 60, // in seconds
          );
          this.giveawaySampleSessionDurationHours = duration.hours;
          this.giveawaySampleSessionDurationMinutes = duration.minutes;
        }
      }
    },
    getAvailabilityTarget(objectives) {
      if (objectives && objectives.length > 0) {
        const result = objectives.find((object) => {
          return object.objective_type === "availability";
        });
        return result ? result.value : null;
      }
      return null;
    },
    getPerformanceTarget(objectives) {
      if (objectives && objectives.length > 0) {
        const result = objectives.find((object) => {
          return object.objective_type === "performance";
        });
        return result ? result.value : null;
      }
      return null;
    },
    getQualityTarget(objectives) {
      if (objectives && objectives.length > 0) {
        const result = objectives.find((object) => {
          return object.objective_type === "quality";
        });
        return result ? result.value : null;
      }
      return null;
    },
    getOOETarget(objectives) {
      if (objectives && objectives.length > 0) {
        const result = objectives.find((object) => {
          return object.objective_type === "ooe";
        });
        return result ? result.value : null;
      }
      return null;
    },
    handleProductionUnitRetrievalError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
    },
    isTargetAvailabilityAuthorized() {
      if (this.productionUnit) {
        return this.puHasRequiredFeature(this.productionUnit.id, PackageFeatures.availability);
      }
      return false;
    },
    isTargetPerformanceAuthorized() {
      if (this.productionUnit) {
        return this.puHasRequiredFeature(this.productionUnit.id, PackageFeatures.performance);
      }
      return false;
    },
    isTargetQualityAuthorized() {
      if (this.productionUnit) {
        return this.puHasRequiredFeature(this.productionUnit.id, PackageFeatures.reject);
      }
      return false;
    },
    isTargetOOEAuthorized() {
      if (this.productionUnit) {
        // If a user is authorized to access OEE, he is also authorized for OOE
        return this.puHasRequiredFeature(this.productionUnit.id, PackageFeatures.oee);
      }
      return false;
    },
    isGiveawaySamplingEnabled() {
      if (this.productionUnit) {
        return (
          this.puHasRequiredFeature(this.productionUnit.id, PackageFeatures.giveaway) &&
          this.productionUnit.giveaway_sampling_configuration
        );
      }
      return false;
    },
    getPerformanceTargetTooltip() {
      if (!this.isTargetPerformanceAuthorized()) {
        return this.$t("productionUnit.details.performanceNotSupportedTooltip");
      }
      return null;
    },
    getPerformanceTargetMandatoryMark() {
      if (this.isTargetPerformanceAuthorized()) {
        return this.$t("productionUnit.details.targetPerformanceValue") + " *";
      } else {
        // No need for the `*` next to the title, the line does not support Performance and the field is disabled.
        return this.$t("productionUnit.details.targetPerformanceValue");
      }
    },
    getQualityTargetTooltip() {
      if (!this.isTargetQualityAuthorized()) {
        return this.$t("productionUnit.details.qualityNotSupportedTooltip");
      }
      return null;
    },
    getQualityTargetMandatoryMark() {
      if (this.isTargetQualityAuthorized()) {
        return this.$t("productionUnit.details.targetQualityValue") + " *";
      } else {
        // No need for the `*` next to the title, the line does not support Quality and the field is disabled.
        return this.$t("productionUnit.details.targetQualityValue");
      }
    },
    getOOETargetTooltip() {
      if (!this.isTargetQualityAuthorized()) {
        return this.$t("productionUnit.details.ooeNotSupportedTooltip");
      }
      return null;
    },
    getOOETargetMandatoryMark() {
      if (this.isTargetOOEAuthorized()) {
        return this.$t("productionUnit.details.targetOOEValue") + " *";
      } else {
        // No need for the `*` next to the title, the line does not support Quality and the field is disabled.
        return this.$t("productionUnit.details.targetOOEValue");
      }
    },
    validateName() {
      if (!this.name) {
        return this.$t("productionUnit.errors.noName");
      }
      if (this.name.trim().length > this.productionUnitNameMaxLength) {
        return this.$t("productionUnit.errors.nameTooLong");
      }
      if (!Validations.isProductionUnitNameValid(this.name)) {
        return this.$t("productionUnit.errors.nameHasInvalidChar");
      }
      return true;
    },
    validateHour(hourValue) {
      const hourAsNumber = Number(hourValue);
      if (!Number.isInteger(hourAsNumber)) {
        return this.$t("productionUnit.errors.invalidHour");
      }
      const h = parseInt(hourValue);
      if (isNaN(h)) {
        return this.$t("productionUnit.errors.invalidHour");
      }
      if (h < 0 || h >= 24) {
        return this.$t("productionUnit.errors.invalidHour");
      }
      return true;
    },
    validateMinute(minuteValue) {
      const minuteAsNumber = Number(minuteValue);
      if (!Number.isInteger(minuteAsNumber)) {
        return this.$t("productionUnit.errors.invalidMinute");
      }
      const m = parseInt(minuteValue);
      if (isNaN(m) || m < 0 || m >= 60) {
        return this.$t("productionUnit.errors.invalidMinute");
      }
      return true;
    },
    validateSecond(value) {
      const minuteAsNumber = Number(value);
      if (!Number.isInteger(minuteAsNumber)) {
        return this.$t("productionUnit.errors.invalidSecond");
      }
      const m = parseInt(value);
      if (isNaN(m) || m < 0 || m >= 60) {
        return this.$t("productionUnit.errors.invalidSecond");
      }
      return true;
    },
    validateAvailabilityTarget() {
      return this.validateTargetValue(this.availabilityTarget);
    },
    validatePerformanceTarget() {
      return this.validateTargetValue(this.performanceTarget);
    },
    validateQualityTarget() {
      return this.validateTargetValue(this.qualityTarget);
    },
    validateOOETarget() {
      return this.validateTargetValue(this.ooeTarget);
    },
    validateTargetValue(targetValue) {
      let m = parseInt(targetValue);
      if (isNaN(m) || m < 0 || m > 100 || !Validations.isProductionUnitObjectiveValid(targetValue)) {
        return this.$t("productionUnit.errors.invalidTargetValue");
      }
      return true;
    },
    validateQuality() {
      const m = parseFloat(this.globalQuality);
      if (isNaN(m) || m <= 0 || m > 100 || !Validations.isProductionUnitQualityValid(this.globalQuality)) {
        return this.$t("productionUnit.errors.invalidQuality");
      }
      return true;
    },
    validateCostPerHour() {
      const m = parseFloat(this.costPerHour);
      if (isNaN(m) || m <= 0) {
        return this.$t("productionUnit.errors.invalidCostPerHour");
      }
      return true;
    },
    validateConvertedUnitId() {
      if (!this.convertedUnitId) {
        return this.$t("productionUnit.errors.noConvertedUnitName");
      }
      if (!Validations.isProductionUnitConvertedUnitValid(this.convertedUnitId)) {
        const supportedValues = this.availableUnitNames.map((unit) => unit.name).join();
        return this.$t("productionUnit.errors.invalidConvertedUnitName", { supportedValues: supportedValues });
      }
      return true;
    },
    validateGiveawaySampleCount() {
      if (this.isGiveawaySamplingEnabled()) {
        if (!this.giveawaySampleCount) {
          return this.$t("productionUnit.errors.invalidGiveawaySampleCount", {
            minCount: this.minSampleCount,
            maxCount: this.maxSampleCount,
          });
        } else {
          if (!isInteger(this.giveawaySampleCount)) {
            return this.$t("productionUnit.errors.invalidGiveawaySampleCount", {
              minCount: this.minSampleCount,
              maxCount: this.maxSampleCount,
            });
          }
          const count = parseInt(this.giveawaySampleCount);
          if (count < this.minSampleCount || count > this.maxSampleCount) {
            return this.$t("productionUnit.errors.invalidGiveawaySampleCount", {
              minCount: this.minSampleCount,
              maxCount: this.maxSampleCount,
            });
          }
        }
      }
      return true;
    },
    fromDuration(h, m, s) {
      const hours = h ? h : 0;
      const minutes = m ? m : 0;
      const seconds = s ? s : 0;
      return hours * 3600 + minutes * 60 + seconds;
    },

    submit() {
      const isValid = this.$refs.productionUnitForm.validate();
      if (isValid) {
        const updatedProductionUnit = {
          id: this.productionUnit.id,
          name: this.name,
          downtime_timeout_in_seconds: this.fromDuration(
            this.downtimeTimeoutHours,
            this.downtimeTimeoutMinutes,
            this.downtimeTimeoutSeconds,
          ),
          end_downtime_timeout_in_seconds: this.fromDuration(
            this.endOfDowntimeTimeoutHours,
            this.endOfDowntimeTimeoutMinutes,
            this.endOfDowntimeTimeoutSeconds,
          ),
          downtime_justification_delay_in_seconds: this.fromDuration(
            this.downtimeJustificationDelayHours,
            this.downtimeJustificationDelayMinutes,
            this.downtimeJustificationDelaySeconds,
          ),

          objectives: this.buildObjectives(),
          converted_unit_name: this.convertedUnitId,
          quality: this.globalQuality,
          cost_per_hour: this.isLoggedInUserFinancialAdmin ? this.costPerHour : null,

          measure_hole_timeout_in_seconds: this.fromDuration(
            this.measureHoleTimeoutHours,
            this.measureHoleTimeoutMinutes,
            this.measureHoleTimeoutSeconds,
          ),
          recurring_downtime_trigger_in_seconds: this.fromDuration(
            this.minScheduledDowntimeDurationHours,
            this.minScheduledDowntimeDurationMinutes,
            this.minScheduledDowntimeDurationSeconds,
          ),
        };

        if (this.isGiveawaySamplingEnabled()) {
          updatedProductionUnit["giveaway_sampling_configuration"] = {
            sample_count: this.giveawaySampleCount,
            sampling_session_duration_minutes: this.fromDuration(
              this.minScheduledDowntimeDurationMinutes,
              this.minScheduledDowntimeDurationHours,
            ),
          };
        }

        ProductionUnitService.update(updatedProductionUnit)
          .then((response) => this.handleProductionUnitUpdateResponse(response, updatedProductionUnit))
          .catch((error) => this.handleProductionUnitUpdateError(error.response));
      }
    },

    buildObjectives() {
      return [
        { objective_type: "oee", value: this.oeeValue },
        { objective_type: "availability", value: this.availabilityTarget },
        { objective_type: "performance", value: this.performanceTarget },
        { objective_type: "quality", value: this.qualityTarget },
        { objective_type: "ooe", value: this.ooeTarget },
      ];
    },
    handleProductionUnitUpdateResponse(httpResponse, updatedProductionUnit) {
      if (httpResponse.status === 200) {
        // If it's the active production unit, update the downtime justification delay
        if (updatedProductionUnit.id === this.activeProductionUnitId) {
          this.setJustificationDelayInSeconds(updatedProductionUnit.downtime_justification_delay_in_seconds);
        }
        this.fetchProductionUnit();
        this.showOperationSuccess(this.$t("productionUnit.successfullyUpdated", { name: updatedProductionUnit.name }));
        this.close();
      } else {
        this.handleProductionUnitUpdateError(httpResponse);
      }
    },
    handleProductionUnitUpdateError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
      this.close();
    },

    getErrorMessage(code) {
      if (code === "CS_PU_PUT_20001") {
        return this.$t("productionUnit.errors.duplicateName", { name: this.name });
      }
      return this.$t("common.errors.default", { code: code });
    },

    close() {
      this.reset();
      this.$refs.productionUnitForm.resetValidation();
      this.$router.push(this.getRouteBack());
    },
    reset() {
      this.name = null;
      this.downtimeTimeoutHours = null;
      this.downtimeTimeoutMinutes = null;
      this.downtimeTimeoutSeconds = null;
      this.endOfDowntimeTimeoutHours = null;
      this.endOfDowntimeTimeoutMinutes = null;
      this.endOfDowntimeTimeoutSeconds = null;
      this.downtimeJustificationDelayHours = null;
      this.downtimeJustificationDelayMinutes = null;
      this.downtimeJustificationDelaySeconds = null;
      this.availabilityTarget = null;
      this.performanceTarget = null;
      this.qualityTarget = null;
      this.globalQuality = null;
      this.convertedUnitId = null;
      this.costPerHour = null;
      this.currencyCode = null;
      this.giveawaySampleCount = null;
      this.giveawaySampleSessionDurationHours = null;
      this.giveawaySampleSessionDurationMinutes = null;
      this.measureHoleTimeoutHours = 0;
      this.measureHoleTimeoutMinutes = 1;
      this.measureHoleTimeoutSeconds = 0;
      this.minScheduledDowntimeDurationHours = 0;
      this.minScheduledDowntimeDurationMinutes = 1;
      this.minScheduledDowntimeDurationSeconds = 0;
    },
  },

  async mounted() {
    await this.fetchProductionUnit();
  },
};
</script>

<style lang="scss" scoped>
legend {
  display: block;
}

.converted-unit-dropdown {
  width: 160px;
}
</style>
