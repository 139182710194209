<template>
  <article class="container-fluid">
    <h1 class="main-title">{{ $t("productionUnit.titleEdition") }}</h1>

    <production-unit-wizard />
  </article>
</template>

<script>
import ProductionUnitWizard from "@/components/productionunit/ProductionUnitWizard";

export default {
  components: {
    ProductionUnitWizard,
  },
};
</script>
